import { Injectable } from '@angular/core';
import { Firestore , doc, getDoc, docData, query, collection, orderBy, collectionData} from '@angular/fire/firestore';
import { from } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(public afs :  Firestore) { }
  
  getWebsite(){
    const docRef =  doc(this.afs, 'website', 'data')
    
    return  docData(docRef)
  }

  getCollection(){
    const colRef = query (collection(this.afs, 'website', 'data', 'teams') , orderBy('sn', 'asc'))
    return collectionData(colRef)
  }

  getLoans(){
    const colRef = query (collection(this.afs, 'website', 'data', 'loans') , orderBy('sn', 'asc'))
    return collectionData(colRef)
  }


  getHomeSlider(){
    const colRef = query(collection(this.afs, 'website', 'data', 'background') , orderBy('sn', 'asc'))
    return collectionData(colRef)
  }

  getCareers(){
    const colRef = query(collection(this.afs, 'website', 'data', 'careers') , orderBy('sn', 'asc'))
    return collectionData(colRef)
  }

  
}

