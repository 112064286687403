import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { DataService } from './services/data.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  values : any;
  loans : any;
  constructor(
    public dataService : DataService, private menuController: MenuController,
    public router : Router
    ) {
    this.dataService.getWebsite().subscribe(snapshot => {
      this.values = snapshot
    
      
    })

    this.dataService.getLoans().subscribe((snap) => {
      this.loans = snap
    })
  }

  closeMenu(){
    this.menuController.close()
  }

  routeUrl(url){
   this.router.navigateByUrl(url)
   this.closeMenu()
  }
}
