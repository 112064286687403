import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'asset',
    loadChildren: () => import('./pages/loans/asset-loan/asset-loan.module').then( m => m.AssetLoanPageModule)
  },
  {
    path: 'beta',
    loadChildren: () => import('./pages/loans/beta-loan/beta-loan.module').then( m => m.BetaLoanPageModule)
  },
  {
    path: 'federal',
    loadChildren: () => import('./pages/loans/federal-loan/federal-loan.module').then( m => m.FederalLoanPageModule)
  },
  {
    path: 'education',
    loadChildren: () => import('./pages/loans/education-loan/education-loan.module').then( m => m.EducationLoanPageModule)
  },
  {
    path: 'salary',
    loadChildren: () => import('./pages/loans/salary-loan/salary-loan.module').then( m => m.SalaryLoanPageModule)
  },
  {
    path: 'small',
    loadChildren: () => import('./pages/loans/small-loan/small-loan.module').then( m => m.SmallLoanPageModule)
  },
  {
    path: 'kaylog',
    loadChildren: () => import('./pages/loans/kaylog-loan/kaylog-loan.module').then( m => m.KaylogLoanPageModule)
  },
  {
    path: 'about',
    loadChildren: () => import('./pages/about-page/about-page.module').then( m => m.AboutPagePageModule)
  },
  {
    path: 'apply',
    loadChildren: () => import('./pages/application-page/application-page.module').then( m => m.ApplicationPagePageModule)
  },
  {
    path: 'blog',
    loadChildren: () => import('./pages/blog-page/blog-page.module').then( m => m.BlogPagePageModule)
  },
  {
    path: 'career',
    loadChildren: () => import('./pages/career-page/career-page.module').then( m => m.CareerPagePageModule)
  },
  {
    path: 'gallery',
    loadChildren: () => import('./pages/gallery-page/gallery-page.module').then( m => m.GalleryPagePageModule)
  },
  {
    path: 'faq',
    loadChildren: () => import('./pages/help-page/help-page.module').then( m => m.HelpPagePageModule)
  },
  {
    path: 'testimonial',
    loadChildren: () => import('./pages/testimonial-page/testimonial-page.module').then( m => m.TestimonialPagePageModule)
  },
  {
    path: 'contact',
    loadChildren: () => import('./pages/contact-page/contact-page.module').then( m => m.ContactPagePageModule)
  },
  {
    path: 'blog-details',
    loadChildren: () => import('./pages/blog-details/blog-details.module').then( m => m.BlogDetailsPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
